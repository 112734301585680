
  import userController from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client-ooi/src/components/PostContent/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://2e23ddba0b184fdfa482ff438a2db1dc@sentry.wixpress.com/3605',
    id: '2e23ddba0b184fdfa482ff438a2db1dc',
    projectName: 'communities-forum-client-ooi',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["wix-forum"],"centralized":true};

  var translationsConfig = {"enabled":false,"icuEnabled":true};

  var biConfig = null;

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client-ooi/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Forum",
    componentName: "PostContent",
    appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
    componentId: "1dadae94-2bd3-4f04-9b39-8fd8c46e39da",
    projectName: "communities-forum-client-ooi",
    defaultTranslations,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client-ooi/src/components/PostContent/controller.ts",
  };

  export const controller = _controller
  export default controller;
