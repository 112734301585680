export const REDUCERS = {
  APP_LOADED: 'appLoaded',
  FAST_FORUM_SUBMIT: 'fastFormSubmit',
  DEBUG_STATE: 'debugState',
  PROMISIFIED_ACTIONS_RESULTS: 'promisifiedActionsResults',
  EXPERIMENTS: 'experiments',
  TRANSLATIONS: 'translations',
  BASIC_PARAMS: 'basicParams',
  MODAL: 'modal', //
  ROUTER: 'router', //
  MESSAGE: 'MESSAGE', //
  COMMUNITIES_CONTEXT: 'communitiesContext',
  INSTANCE_VALUES: 'instanceValues',
  IS_LOADED: 'isLoaded',
  IS_LOADING: 'isLoading',
  MEMBERS_GROUPS: 'membersGroups',
  LOCATION: 'location',
  MA_NAVIGATION: 'maNavigation',
  PAGINATION: 'pagination',
  POSTS: 'posts',
  SEARCH: 'search',
  CATEGORIES: 'categories',
  RCE_TRANSLATIONS: 'rceTranslations',
  CATEGORIES_MANAGER: 'categoriesManager',
  CURRENT_USER: 'currentUser',
  SORTING: 'sorting',
  FOOTER_POSTS: 'footerPosts',
  RELATED_POSTS: 'relatedPosts',
  BADGES: 'badges',
  PAID_PLANS: 'paidPlans',
  STYLE_PARAMS: 'styleParams',
  SAVED_ACTION: 'savedAction',
  FILTERING: 'filtering',
  OFFLINE_MIGRATION: 'offlineMigration',
  RELOAD_PAGE: 'reloadPage',
  GLOBAL_LOADER: 'globalLoader',
  WHO_LIKED: 'whoLiked',
  SITE_ID: 'siteId',
  SIMILAR_POSTS: 'similarPosts',
  EXTERNAL_LINKS: 'externalLinks',
  LOCAL_STORAGE: 'localStorage',
  COUNTERS: 'counters',
  FORUM_DATA: 'forumData',
};
