import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServicesMap, FilterParams } from '@common/services';
import { DecoratedComment } from '@api/member.models';
import { Status } from '../member-slice.models';
import {
  MemberCommentsSlice,
  MemberCommentsData,
} from './member-comments-slice.models';
import {
  PartialState,
  getMemberComments,
} from './member-comments-slice.selectors';
import { PartialState as BasePropsState } from '@common/store/base-props-slice';

export type ThunkConfig = {
  extra: {
    services: ServicesMap;
  };
};

interface InitialFetchParams {
  userId: string;
  filter?: FilterParams;
}

export const fetchInitialMemberComments = createAsyncThunk<
  MemberCommentsSlice,
  InitialFetchParams,
  ThunkConfig
>(
  'memberPosts/fetchInitialMemberComments',
  (
    { userId, filter },
    { getState, extra: { services } },
  ): Promise<MemberCommentsSlice> => {
    const state = getState() as PartialState;
    const memberComments = getMemberComments(state);

    if (memberComments.status === Status.INITIAL) {
      if (!userId) {
        throw new Error('Missing user id.');
      }

      return services.memberService
        .getComments({ userId, ...filter })
        .then(({ pagination, data }) => {
          return {
            user: { id: userId },
            pagination,
            comments: data,
          };
        });
    } else {
      // @TODO handle without braking UI
      throw new Error('Not ready for state transition.');
    }
  },
);

export const fetchMemberComments = createAsyncThunk<
  MemberCommentsData,
  FilterParams,
  ThunkConfig
>(
  'memberPosts/fetchMemberComments',
  (filter = {}, { getState, extra: { services } }) => {
    const state = getState() as PartialState;
    const memberComments = getMemberComments(state);

    if (
      memberComments.status === Status.PENDING ||
      memberComments.status === Status.PENDING_AFTER_DELETE
    ) {
      const {
        user: { id },
      } = memberComments;

      if (!id) {
        throw new Error('Missing user id.');
      }

      return services.memberService
        .getComments({ userId: id, ...filter })
        .then(({ pagination, data }) => {
          return { pagination, comments: data };
        });
    } else {
      // @TODO discuss error message structure
      throw new Error('Missing user data.');
    }
  },
);

export const deleteMemberComment = createAsyncThunk<
  Promise<unknown>,
  DecoratedComment,
  ThunkConfig
>(
  'memberPosts/deleteMemberComment',
  async (comment, { getState, extra: { services } }) => {
    const state = getState() as PartialState & BasePropsState;
    const memberComments = getMemberComments(state);

    if (memberComments.status === Status.PENDING) {
      const { id, post } = comment;

      return services.memberService.deleteComment({
        postId: post.id,
        commentId: id,
      });
    } else {
      // @TODO handle without braking UI
      throw new Error('Not ready for state transition.');
    }
  },
);

export default {
  fetchInitialMemberComments,
  fetchMemberComments,
  deleteMemberComment,
};
