import { get } from 'lodash';
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_COMPACT,
  LAYOUT_TYPE_PATH,
  LAYOUT_TYPE_MAIN_PAGE_PATH,
} from '@wix/communities-forum-client-commons';

export const getLayoutType = (state, style) => {
  const layout = get(style, LAYOUT_TYPE_PATH, LAYOUT_TYPE_CLASSIC);
  return layout === LAYOUT_TYPE_COMPACT ? LAYOUT_TYPE_CLASSIC : layout;
};

export const getMainPageLayoutType = (state, style) =>
  get(style, LAYOUT_TYPE_MAIN_PAGE_PATH, getLayoutType(state, style));
