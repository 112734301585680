import { getRichContentFeatures } from '@wix/rich-content-features';
import { EXPERIMENT_FORCE_CAPTCHA_BY_CONTENT } from '@wix/communities-forum-client-commons';
import { getCaptchaSettings } from '../../selectors/forum-data-selectors';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { hasSuspiciousFields } from '../../../common/services/captcha-helpers';
import { RootState } from '../../types/store-types';

export const shouldShowCaptcha = (state: RootState, commentContent: any) => {
  const {
    enableOnCommentCreation: isCaptchaOnCommentEnabled,
    enableOnSuspiciousActivity: isCaptchaOnSuspiciousEnabled,
  } = getCaptchaSettings(state);

  const isCaptchaByContentForced = isExperimentEnabled(state, EXPERIMENT_FORCE_CAPTCHA_BY_CONTENT);

  let isCaptchaForContent = false;

  if (isCaptchaByContentForced || isCaptchaOnSuspiciousEnabled) {
    const contentFeatures = getRichContentFeatures(commentContent);

    isCaptchaForContent = hasSuspiciousFields(contentFeatures);
  }

  return (
    isCaptchaForContent ||
    isCaptchaOnCommentEnabled ||
    // Temporary, should be removed, when decision on default captcha settings is made
    (isCaptchaByContentForced && isCaptchaForContent)
  );
};
