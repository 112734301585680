import {
  EditorReadyFn,
  GetAppManifestFn,
  FlowEditorSDK,
  HandleActionFn,
  TFunction,
  OnEventFn,
} from '@wix/yoshi-flow-editor';

import { withMembersArea } from '@wix/members-area-integration-kit';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';
import { BM, BM_CREATE_POST, BM_CATEGORIES } from './editor/constants/routes';
import { setDefaultForumStyles } from './editor/utils/set-default-forum-styles';
import { getComponentByAppId } from './editor/utils/find-component';
import { initializeI18N } from './editor/utils/i18n-provider';
import { TabId } from './components/Forum/Settings/constants/tab-id';
import { removeOldComponents } from './editor/migrations/old-components-removal';
import { OLD_COMPONENTS_REMOVAL_MIGRATION_ID } from './editor/constants/migrations';

const CREATE_POST_ACTION = 'openCreatePost';
const MANAGE_POSTS_ACTION = 'openBusinessManager';
const EDIT_CATEGORIES_ACTION = 'openEditCategories';
const EDIT_LAYOUT_ACTION = 'openEditLayout';
const EDIT_DESIGN_ACTION = 'openEditDesign';

const TPA_PAGE_ID_POST = 'Forum Post';
const POST_PAGE_WIDGET_ID = '44e026ea-d6e4-47c8-ae1f-01a5a789f264';
const POST_CONTENT_WIDGET_ID = '1dadae94-2bd3-4f04-9b39-8fd8c46e39da';

const WIX_COMMENTS_APP_DEF_ID = '91c9d6a7-6667-41fb-b0b4-7d3b3ff0b02e';
const WIX_COMMENTS_WIDGET_ID = '1c154801-444a-49ad-aee8-325ff74f89b1';

const ADD_FORUM_POST_PAGE_EXPERIMENT = 'specs.wixForum.AddForumPostPageInEditor';

const handleMigrate = async (
  editorSDK: FlowEditorSDK,
  appToken: string,
  { migrationId }: { migrationId: string },
) => {
  switch (migrationId) {
    case OLD_COMPONENTS_REMOVAL_MIGRATION_ID: {
      return removeOldComponents(editorSDK, appToken);
    }
  }
};

const addForumPostPage = async (appToken: string, editorSDK: FlowEditorSDK) => {
  const allPages = await editorSDK.pages.data.getAll(appToken);
  const forumPostPageExists = allPages.some((p) => p.tpaPageId === 'Forum Post');

  if (forumPostPageExists) {
    return;
  }

  await editorSDK.document.tpa.add.component(appToken, {
    managingAppDefId: forumAppDefId,
    componentType: editorSDK.document.tpa.TPAComponentType.Page,
    appDefinitionId: forumAppDefId,
    page: {
      pageId: 'Forum Post',
    },
  });
};

const openAppSettingsTab = async (
  tab: TabId.LAYOUT_TAB | TabId.DESIGN_TAB,
  editorSDK: FlowEditorSDK,
  appToken: string,
) => {
  const locale = await editorSDK.environment.getLocale(forumAppDefId);
  const currency = await editorSDK.info.getCurrency(forumAppDefId);
  const appData = await editorSDK.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, editorSDK, appData.applicationId);
  const componentRef = await editorSDK.components.getById(appToken, { id: component.id });
  await editorSDK.document.pages.navigateTo(forumAppDefId, {
    pageLink: { type: 'PageLink', pageId: component.pageId },
  });
  const settingsUrl = (appData as any).widgets['1489040e-001f-4631-55a9-2c29b4417126'].settings
    .urlV2;

  return editorSDK.editor.openComponentPanel(appToken, {
    url: `${settingsUrl}?instance=${appData.instance}&appDefinitionId=${appData.appDefinitionId}&applicationId=${appData.applicationId}&compId=tpaSettings&currency=${currency}&endpointType=settings&locale=${locale}&origCompId=${component.id}&viewMode=editor&tab=${tab}`,
    type: editorSDK.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: 528,
    title: 'Forum',
  });
};

export const getComponentRef = async (
  appToken: string,
  editorSDK: FlowEditorSDK,
  widgetId: string,
) => {
  const forumAppData = await editorSDK.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  if (!forumAppData) {
    return;
  }

  const forumAppComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    appToken,
    forumAppData.applicationId,
  );
  if (!forumAppComponents) {
    return;
  }

  const component = forumAppComponents.find((c) => c.widgetId === widgetId);
  if (!component) {
    return;
  }

  return editorSDK.document.components.getById(appToken, { id: component.id });
};

const handleWidgetsConnectionToPostPage = async (
  appToken: string,
  editorSDK: FlowEditorSDK,
  componentId: string,
) => {
  const currentPageRef = await editorSDK.document.pages.getCurrent(appToken);
  const currentPageStructure = await editorSDK.document.pages.serialize(appToken, {
    pageRef: currentPageRef,
    maintainIdentifiers: true,
  });

  // Make sure we are on the right page
  if (currentPageStructure?.data?.tpaPageId !== TPA_PAGE_ID_POST) {
    return;
  }

  const postPageWidgetComponentRef = await getComponentRef(
    appToken,
    editorSDK,
    POST_PAGE_WIDGET_ID,
  );

  if (!postPageWidgetComponentRef) {
    return;
  }

  const compRef = await editorSDK.document.components.getById(appToken, {
    id: componentId,
  });

  return editorSDK.document.controllers.connect(appToken, {
    connectToRef: compRef,
    controllerRef: postPageWidgetComponentRef,
    role: 'forum-post-page',
    isPrimary: false,
  });
};

const addPostContentComponent = async (appToken: string, editorSDK: FlowEditorSDK) => {
  const componentData = await editorSDK.document.tpa.add.component('', {
    managingAppDefId: forumAppDefId,
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: forumAppDefId,
    widget: {
      widgetId: POST_CONTENT_WIDGET_ID,
    },
  });

  return handleWidgetsConnectionToPostPage(appToken, editorSDK, componentData.compId);
};

const addPostCommentsComponent = async (appToken: string, editorSDK: FlowEditorSDK) => {
  const componentData = await editorSDK.document.tpa.add.component('', {
    managingAppDefId: forumAppDefId,
    componentType: editorSDK.document.tpa.TPAComponentType.Widget,
    appDefinitionId: WIX_COMMENTS_APP_DEF_ID,
    widget: {
      widgetId: WIX_COMMENTS_WIDGET_ID,
    },
  });

  return handleWidgetsConnectionToPostPage(appToken, editorSDK, componentData.compId);
};

const editorApi: {
  appToken: string | undefined;
  editorSDK: FlowEditorSDK | undefined;
  t: TFunction | undefined;
  editorReady: EditorReadyFn;
  handleAction: HandleActionFn;
  getAppManifest: GetAppManifestFn;
  onEvent: OnEventFn;
} = {
  appToken: undefined,
  editorSDK: undefined,
  t: undefined,
  editorReady: async (editorSDK, appToken, options, flowAPI) => {
    const shouldAddForumPostPage = flowAPI.experiments.enabled(ADD_FORUM_POST_PAGE_EXPERIMENT);
    editorApi.appToken = appToken;
    editorApi.editorSDK = editorSDK;

    if (shouldAddForumPostPage) {
      await addForumPostPage(appToken, editorSDK);
    }

    if (options.firstInstall) {
      await setDefaultForumStyles(appToken, editorSDK);
    }

    const i18n = await initializeI18N(editorSDK, appToken, flowAPI.httpClient);
    editorApi.t = i18n.t.bind(i18n);
  },
  handleAction: async ({ type, payload }, editorSDK) => {
    const { appToken } = editorApi;
    if (!appToken) {
      return;
    }

    try {
      switch (type) {
        case 'appInstalled':
          switch ((payload as { appDefinitionId: string }).appDefinitionId) {
            case forumAppDefId: {
              const [forumAppData = { applicationId: '' }, allPages = []] = await Promise.all([
                editorSDK.tpa.app.getDataByAppDefId(appToken, forumAppDefId),
                editorSDK.pages.data.getAll(appToken),
              ]);

              const forumPage = allPages.find(
                (page) =>
                  page.tpaPageId === 'forum' &&
                  page.tpaApplicationId === forumAppData?.applicationId,
              );

              await (forumPage &&
                editorSDK.document.pages.navigateTo(appToken, {
                  pageLink: {
                    type: 'PageLink',
                    pageId: forumPage.id!,
                  },
                }));
              return editorSDK.document.save().catch(function () {});
            }
            default:
              return Promise.resolve();
          }

        case 'migrate':
          return handleMigrate(editorSDK, appToken, payload as any);

        default:
          return Promise.resolve();
      }
    } catch (e) {
      throw e;
    }
  },
  getAppManifest: () => {
    const { t } = editorApi;

    if (!t) {
      return {};
    }

    return {
      appDescriptor: {
        mainActions: [
          {
            title: t('App_Manager_Wix_Forum_Main_CTA'),
            actionId: CREATE_POST_ACTION,
            icon: 'appManager_addElementsAction',
          },
          {
            title: t('App_Manager_Wix_Forum_Main_CTA_2'),
            actionId: MANAGE_POSTS_ACTION,
            icon: 'appManager_settingsAction',
          },
        ],
        customActions: [
          {
            title: t('App_Manager_Wix_Forum_Quick_Action_1'),
            actionId: EDIT_CATEGORIES_ACTION,
            icon: 'appManager_settingsAction',
            type: 'editorActions',
          },
          {
            title: t('App_Manager_Wix_Forum_Quick_Action_2'),
            actionId: EDIT_LAYOUT_ACTION,
            icon: 'base-composition',
            type: 'editorActions',
          },
          {
            title: t('App_Manager_Wix_Forum_Quick_Action_3'),
            actionId: EDIT_DESIGN_ACTION,
            icon: 'customizeDesignButtonBrush',
            type: 'editorActions',
          },
        ],
        defaultActions: {
          learnMoreKB: '6dc5a0f2-c3b1-4ec4-96f4-94e70bbf3a48',
        },
      },
    };
  },
  onEvent: async ({ eventType, eventPayload }, editorSDK) => {
    const { appToken } = editorApi;
    if (!appToken) {
      return;
    }

    switch (eventType) {
      case 'appActionClicked':
        switch (eventPayload && eventPayload.actionId) {
          case CREATE_POST_ACTION:
            await editorSDK.editor.openDashboardPanel(appToken, {
              url: BM_CREATE_POST,
              closeOtherPanels: true,
            });
            break;
          case MANAGE_POSTS_ACTION:
            editorSDK.editor.openDashboardPanel(appToken, { url: BM, closeOtherPanels: true });
            break;
          case EDIT_CATEGORIES_ACTION:
            editorSDK.editor.openDashboardPanel(appToken, {
              url: BM_CATEGORIES,
              closeOtherPanels: true,
            });
            break;
          case EDIT_LAYOUT_ACTION:
            await openAppSettingsTab(TabId.LAYOUT_TAB, editorSDK, appToken);
            break;
          case EDIT_DESIGN_ACTION:
            await openAppSettingsTab(TabId.DESIGN_TAB, editorSDK, appToken);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  },
};

const editorApiWithMA = withMembersArea(editorApi, {
  disableADI: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.ALL_MEMBERS,
    MA_APP_IDS.FOLLOWERS,
    MA_APP_IDS.FORUM_COMMENTS,
    MA_APP_IDS.FORUM_POSTS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
  ],
});

export const exports = (editorSDK: FlowEditorSDK) => {
  return {
    private: {
      addPostContentComponent: async () => {
        return addPostContentComponent('', editorSDK);
      },
      addPostCommentsComponent: async () => {
        return addPostCommentsComponent('', editorSDK);
      },
    },
    public: {
      connectWidgetToPostPage: async (compId: string) => {
        return handleWidgetsConnectionToPostPage('', editorSDK, compId);
      },
    },
  };
};

export const editorReady = editorApiWithMA.editorReady;
export const handleAction = editorApiWithMA.handleAction;
export const getControllerPresets = undefined;
export const getAppManifest = editorApiWithMA.getAppManifest;
export const onEvent = editorApiWithMA.onEvent;
