import createAction from '../services/create-action';
import { getTranslations } from '../store/translations/translations-selectors';
import { setTranslations } from '../store/translations/translations-actions';
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/src';
import { forumActivityFeatureApi } from '../../api/user/forum-activity.api';

export const SET_EXPERIMENTS_SUCCESS = 'experiments/SET_EXPERIMENTS_SUCCESS';

export const setExperimentsSuccess = createAction(SET_EXPERIMENTS_SUCCESS);

export function setExperiments(experimentsBag = {}) {
  return async (dispatch, getState, { request }) => {
    const currentTranslations = getTranslations(getState());
    const translationsWithExperiments = abTranslate(experimentsBag, currentTranslations);
    dispatch(setTranslations(translationsWithExperiments));

    let recentActivityCatsEnabled = 'true';
    try {
      const result = await request.httpClient.request(forumActivityFeatureApi());
      recentActivityCatsEnabled = result.data.enabled;
    } catch {}

    return dispatch(
      setExperimentsSuccess({
        ...experimentsBag,
        [EXPERIMENT_RECENT_ACTIVITY_CATS]: recentActivityCatsEnabled,
      }),
    );
  };
}
