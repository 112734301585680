import { isRejected } from '@reduxjs/toolkit';

import { setError } from './errors-slice.actions';

export const initErrorMonitorMiddleware = (
  errorLogger: (error: Error) => void,
) => (store: any) => (next: any) => (action: any) => {
  if (isRejected(action)) {
    console.error(action);
    errorLogger(action.error);
    store.dispatch(setError(action.error.message));
  }

  return next(action);
};
