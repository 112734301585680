import { createSlice } from '@reduxjs/toolkit';

import { TranslationsState } from './translations-slice.models';
import { fetchTranslations } from './translations-slice.actions';

export const TRANSLATIONS_SLICE_NAME = 'translations';

const initialState: TranslationsState = {
  isLoading: false,
  translations: {},
};

const translationsSlice = createSlice({
  name: TRANSLATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTranslations.pending, state => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(fetchTranslations.fulfilled, (_, { payload }) => {
      return {
        ...payload,
        isLoading: false,
      };
    });
    builder.addCase(fetchTranslations.rejected, state => {
      return {
        ...state,
        isLoading: false,
      };
    });
  },
});

export const reducer = translationsSlice.reducer;
