import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { PostContentController } from '../PostContent/post-content-controller.type';
import { getPost } from '../../api/post/get-post.api';
import { getPostRelatedSlugs } from './post.utils';
import { Post } from '../../types';
import { getFirstPost } from '../../api/post/get-first-post.api';

type WixCommentsController = {
  setResourceId: (resourceId: string) => void;
};

type CommonControllerProps = {
  show: () => void;
  hide: () => void;
};

type SupportedControllers = ((PostContentController | WixCommentsController) &
  CommonControllerProps)[];

const createController: CreateControllerFn = async ({
  controllerConfig: { $w, setProps, wixCodeApi },
  flowAPI,
}) => {
  return {
    async pageReady() {
      setProps({
        wfState: { type: 'LOADING' },
        meta: {
          isEditor: wixCodeApi.window.viewMode === 'Editor',
        },
      });

      const { categorySlug, postSlug } = await getPostRelatedSlugs(wixCodeApi);
      const postPageConnections: SupportedControllers = $w('@forum-post-page');

      console.log('@forum-post-page connections', postPageConnections);

      try {
        let post: Post;
        if (wixCodeApi.window.viewMode === 'Site') {
          const { data } = await flowAPI.httpClient.request(
            getPost({ postSlug, categorySlug }),
          );
          post = data;
        } else {
          // We don't handle url in editor yet, so fetching first post instead.
          const { data } = await flowAPI.httpClient.request(getFirstPost());
          post = data;
        }

        postPageConnections.forEach((controller) => {
          if ('setPost' in controller) {
            controller.setPost(post);
          }

          if ('setResourceId' in controller) {
            controller.setResourceId(post.id);
          }

          // Widgets are hidden by default to avoid flashing content
          controller.show();
        });
        setProps({ wfState: { type: 'READY' } });
      } catch (e) {
        setProps({ wfState: { type: 'ERROR', error: e } });
        postPageConnections.forEach((controller) => {
          controller.hide();
        });
      }
    },
  };
};

export default createController;
