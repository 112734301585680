import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { ThreeDotsLoader } from 'wix-ui-tpa';
import styles from './root.scss';

type PostPageState =
  | {
      type: 'LOADING';
    }
  | {
      type: 'READY';
    }
  | { type: 'ERROR'; error: Error };

export type ControllerProps = {
  wfState?: PostPageState;
  meta?: {
    isEditor: boolean;
  };
};

const Widget: FC<WidgetProps<ControllerProps>> = ({ wfState, meta }) => {
  return (
    <>
      {meta?.isEditor ? 'π' : ''}
      {(() => {
        switch (wfState?.type) {
          case 'LOADING': {
            return (
              <div className={styles.loaderContainer}>
                <ThreeDotsLoader />
              </div>
            );
          }
          case 'ERROR': {
            return (
              <div className={styles.errorContainer}>
                <h3>Error occurred</h3>
                <p>{wfState.error.message}</p>
              </div>
            );
          }
          case 'READY': {
          }
          default: {
          }
        }
      })()}
    </>
  );
};

export default Widget;
