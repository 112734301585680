import { URI, EXPERIMENT_URL_CUSTOMIZATION } from '@wix/communities-forum-client-commons';
import getOuterUrl from './get-outer-url';
import { buildCustomizedUrl } from './build-customized-url';

import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { getSectionUrl } from '../../common/store/location/location-selectors';

export const buildNewDeepCommentUrl = (
  categorySlug,
  postSlug,
  commentId,
  url = '',
  searchTerm = '',
) => {
  const query = new URI(url).query();
  const queryString = query
    ? `?${query}${searchTerm ? '&' + searchTerm : ''}`
    : searchTerm
    ? `?${searchTerm}`
    : '';

  return `/${categorySlug}/${postSlug}/dl-${commentId}${queryString}`;
};

export const buildDeepLink = (state, commentId, postId) => {
  const path = `/main/comment/${commentId}`;
  const sectionUrl = getSectionUrl(state);
  const customizedUrl =
    isExperimentEnabled(state, EXPERIMENT_URL_CUSTOMIZATION) && buildCustomizedUrl(state, path);
  const url = URI(customizedUrl || getOuterUrl(path, sectionUrl));
  if (postId) {
    url.setQuery({ postId });
  }
  return url.toString();
};
