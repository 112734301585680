export const COMMENTS_PER_PAGE_DESKTOP = 20;
export const COMMENTS_PER_PAGE_MOBILE = 20;
export const POSTS_PER_PAGE = 30;
export const SEARCH_RESULTS_PER_PAGE_MOBILE = 10;
export const SEARCH_RESULTS_PER_PAGE_DESKTOP = 20;

export const getCommentsPerPage = (isMobile) =>
  isMobile ? COMMENTS_PER_PAGE_MOBILE : COMMENTS_PER_PAGE_DESKTOP;

export const getSearchResultsPerPage = (isMobile) =>
  isMobile ? SEARCH_RESULTS_PER_PAGE_MOBILE : SEARCH_RESULTS_PER_PAGE_DESKTOP;
