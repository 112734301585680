import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServicesMap } from '@common/services';

export type ThunkConfig = {
  extra: {
    services: ServicesMap;
  };
};

export const fetchTranslations = createAsyncThunk<any, string, ThunkConfig>(
  'translations/fetchTranslations',
  (language, { extra: { services } }) => {
    return services.translationsService.getTranslations(language).then(data => {
      return {
        translations: data,
      };
    });
  },
);

export default {
  fetchTranslations,
};
