import { IHttpClient } from '@wix/yoshi-flow-editor';
import { Order } from '@wix/ambassador-forum-v1-post/types';

import {
  memberPostsApi,
  memberCommentsApi,
  deleteMemberComment,
} from '@api/member.api';
import { CommonRequest } from '../services.types';
import { CurrentUser, PostFetchParams } from './member.models';

export type MemberService = ReturnType<typeof createMemberService>;

// @TODO move to consts file
const PAGE_SIZE = 20;

export const createMemberService = ({
  httpClient,
  communitiesApiBase,
}: {
  httpClient: IHttpClient;
  baseApi: CommonRequest;
  communitiesApiBase: CommonRequest;
}) => {
  const getCurrentUserData = async (): Promise<CurrentUser> => {
    return communitiesApiBase('/users/site-member/current');
  };

  const getPosts = async ({
    userId,
    sort = 'createdDate',
    order = Order.DESC,
    offset = 0,
    size = PAGE_SIZE,
    limit = PAGE_SIZE,
  }: PostFetchParams) => {
    try {
      const { data } = await httpClient.request(
        memberPostsApi(userId, {
          limit,
          offset,
          sort,
          order,
        }),
      );

      const paginationConfig = {
        itemCount: data.metaData?.total!,
        pageSize: PAGE_SIZE,
        pageCount: Math.ceil(data.metaData?.total! / PAGE_SIZE),
        currentFilter: {
          limit,
          offset,
          sort,
          order,
          size,
        },
      };

      return { pagination: paginationConfig, data: data.posts };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to fetch user posts.');
    }
  };

  const deletePost = (postId: string) => {
    return communitiesApiBase.delete(`/posts/${postId}`);
  };

  const getComments = async ({
    userId,
    sort = 'createdDate',
    order = Order.DESC,
    offset = 0,
    size = PAGE_SIZE,
    limit = PAGE_SIZE,
  }: PostFetchParams) => {
    try {
      const {
        data: { comments, metaData },
      } = await httpClient.request(
        memberCommentsApi(userId, {
          limit,
          offset,
          sort,
          order,
        }),
      );

      const paginationConfig = {
        itemCount: metaData?.total!,
        pageSize: PAGE_SIZE,
        pageCount: Math.ceil(metaData?.total! / PAGE_SIZE),
        currentFilter: {
          limit,
          offset,
          sort,
          order,
          size,
        },
      };

      return { pagination: paginationConfig, data: comments };
    } catch (e) {
      throw new Error('Failed to fetch user comments.');
    }
  };

  const deleteComment = ({
    postId,
    commentId,
  }: {
    postId: string;
    commentId: string;
  }) => {
    return httpClient.request(deleteMemberComment({ commentId, postId }));
  };

  return {
    _name_: 'memberService' as const,
    getCurrentUserData,
    getPosts,
    deletePost,
    getComments,
    deleteComment,
  };
};
