import webBiLogger from '@wix/web-bi-logger';
import Experiments from '@wix/wix-experiments';
import {EStep} from '../bi/params';
import {OnBoardingApi} from '../services/onboarding-api';
import {OnBoardingStore} from '../stores/onboarding-store';
import {IEditorSDK, SetCurrentStep} from '../types/wix-types';
import {enrichLanguage, resolveAppUrl} from '../utils/editorSDKhelper';
import {createLinguistAPI} from './editorScriptHelper';
import {EOrigin} from '../bi/params';
// shame to use ./bi. only for 2 BIs
const factory = webBiLogger.factory({endpoint: 'linguist'});
const logger = factory.logger();
let experiments: Experiments;
let _editorSDK: IEditorSDK;
let _token: string;
let currentStep: string;
let translation: {
  MULTILINGUAL_ONBOARDING_MODAL_TITLE: string;
};
let appDefId: string;
let linguistAPI: OnBoardingApi;
let onboardingOrigin: EOrigin;
// https://github.com/wix-private/santa-editor/commit/f5b022cd277a86a30aaad3a107943ac970a1fe84#diff-ac44ade209822fbc5afcd6c19d792542R213
export enum OnboardingEventType {
  openOnboarding = 'openOnboarding',
  openDashboard = 'openDashboard',
}

interface OnboardingEvent {
  eventType: OnboardingEventType;
  eventPayload?: {origin: EOrigin};
}

const setCurrentStep: SetCurrentStep = (step) => (currentStep = step);
const onEvent = (event: OnboardingEvent) => {
  if (!event) {
    return;
  }
  switch (event.eventType) {
    case OnboardingEventType.openOnboarding:
      if (event.eventPayload?.origin) {
        onboardingOrigin = event.eventPayload?.origin;
      }
      openOnboardingModal();
      break;
    case OnboardingEventType.openDashboard:
      openDashboardModal();
      break;
    default:
  }
};

const openDashboardModal = async () => {
  await _editorSDK.editor.openDashboardPanel(_token, {url: 'multilingual', closeOtherPanels: true});
  const instance = OnBoardingStore.extractInstanceId(await _editorSDK.info.getAppInstance());
  const {
    data: {
      data: {originalLanguage, translationLanguages},
    },
  } = await linguistAPI.getLanguagesStatus(instance);
  _editorSDK.language.setLanguages(_token, {
    originalLanguage: enrichLanguage(originalLanguage[0]),
    translationLanguages: translationLanguages.map(enrichLanguage),
  });
};

const handleAction = async (action: {type: string; payload: object}) => {
  if (action.type === 'beforeRemoveApp') {
    const origLang = await _editorSDK.language.original.get();
    const translationLanguages = await _editorSDK.language.getTranslationLanguages();
    const allComponents = await _editorSDK.document.components.getAllComponents(_token);
    let index = 0;
    while (index < allComponents?.length) {
      const componentRef = allComponents[index];
      const componentRefType = await _editorSDK.document.components.getType(_token, {componentRef});
      if (!componentRef.id.includes('_r_')) {
        /*checking it's not a referred component. https://jira.wixpress.com/browse/DM-6188*/
        if (componentRefType === 'wysiwyg.viewer.components.LanguageSelector') {
          _editorSDK.document.components.remove(_token, {componentRef});
        } else {
          translationLanguages.forEach((lang) => {
            _editorSDK.language.component.data.remove(_token, {
              languageCode: lang.code,
              componentRef,
            });
          });
        }
      }
      index++;
    }
    _editorSDK.language.setLanguages(_token, {
      originalLanguage: {...origLang, languageCode: origLang.code},
      translationLanguages: [],
    });
  }
};

const editorReady = async (
  editorSDK: IEditorSDK,
  token: string,
  {firstInstall, origin}: {firstInstall: boolean; origin: {type: string; info?: {type: EOrigin}}}
) => {
  const locale = await editorSDK.environment.getLocale();
  onboardingOrigin = origin.info?.type ?? EOrigin.ADD_PANEL;
  try {
    translation = require(`../assets/locale/gen_EditorScript/messages_${locale}.json`);
  } catch (e) {
    translation = require(`../assets/locale/gen_EditorScript/messages_en.json`);
  }
  _editorSDK = editorSDK;
  _token = token;
  appDefId = await editorSDK.info.getAppDefinitionId(token);
  const editorSessionId = await editorSDK.editor.info.getEditorSessionId();
  const metaSiteId = await editorSDK.document.info.getMetaSiteId();
  factory.updateDefaults({src: 89, msid: metaSiteId, esi: editorSessionId});

  _editorSDK.editor.setAppAPI(token, {
    setCurrentStep,
  });

  experiments = new Experiments({scope: 'multilingual-app', useNewApi: true});
  if (firstInstall) {
    linguistAPI = await createLinguistAPI(editorSDK);
    await openModal();
  }
};

async function openOnboardingModal() {
  await experiments.ready();
  const onboardingV2Enabled = experiments.enabled('specs.ml.onboardingV2');
  const options = {
    url: resolveAppUrl(appDefId),
    initialData: {origin: onboardingOrigin.toLowerCase()},
    title: translation.MULTILINGUAL_ONBOARDING_MODAL_TITLE,
    width: onboardingV2Enabled ? '600' : '697',
    height: onboardingV2Enabled ? '576' : '644',
  };

  const result = await _editorSDK.editor.openModalPanel(_token, {
    ...options,
    componentRef: {},
  });

  if (!result) {
    if (onboardingV2Enabled) {
      if (currentStep === EStep.WHATS_NEXT) {
        logger.log({evid: 222, finish_method: 'X'});
      } else {
        logger.log({evid: 306, finish_method: 'X'});
      }
    } else {
      logger.log({evid: 218, step: currentStep, finish_method: 'X'});
    }
  }
}

async function openModal() {
  const [mainLanguage, secondaryLanguages] = await Promise.all([
    _editorSDK.language.original.get(),
    _editorSDK.language.getTranslationLanguages(),
    experiments.ready(),
  ]);
  logger.log({
    evid: 280,
    main_language: mainLanguage?.code || '',
    secondary_languages: secondaryLanguages.map(({code}) => code).join(','),
  });
  if (secondaryLanguages.length) {
    openDashboardModal();
  } else {
    openOnboardingModal();
  }
}

export {onEvent, setCurrentStep, editorReady, handleAction};
