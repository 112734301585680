import { IWixAPI } from '@wix/yoshi-flow-editor';
import {
  POST_PAGE_SECTION_ID,
  FORUM_APP_DEF_ID,
} from '../../constants/app.constants';

export const getPostRelatedSlugs = async (wixCodeApi: IWixAPI) => {
  let { relativeUrl: sectionUrl } = await wixCodeApi.site.getSectionUrl({
    sectionId: POST_PAGE_SECTION_ID,
    appDefinitionId: FORUM_APP_DEF_ID,
  });

  sectionUrl = sectionUrl ? sectionUrl.replace('/', '') : '';
  const [categorySlug, postSlug] = wixCodeApi.location.path.filter(
    (p, index) => index !== 0 && p !== sectionUrl,
  );
  return { categorySlug, postSlug };
};
