import React, { FC } from 'react';
import { useExperiments, WidgetProps } from '@wix/yoshi-flow-editor';
import { Post } from '../../../types';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/viewer';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/viewer';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginSpoiler } from 'wix-rich-content-plugin-spoiler/viewer';
import { pluginCollapsibleList } from 'wix-rich-content-plugin-collapsible-list/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/loadable/viewer';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../stylesParams';

export type ControllerProps = {
  wfState?: {
    post: Post;
  };
  siteMetadata: {
    locale: string;
    isMobile: boolean;
    instance?: string;
  };
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  wfState = {},
  siteMetadata,
}) => {
  const styles = useStyles();
  const { post } = wfState;
  const { experiments } = useExperiments();

  const plugins = React.useMemo(() => {
    return [
      pluginLink(),
      pluginHashtag(),
      pluginImage(),
      pluginVideo(),
      pluginDivider(),
      pluginHtml(),
      pluginMentions(),
      pluginCodeBlock(),
      pluginTextColor(),
      pluginTextHighlight(),
      pluginGiphy(),
      pluginEmoji(),
      pluginFileUpload(),
      pluginLinkPreview(),
      pluginVerticalEmbed(),
      pluginSpoiler(),
      pluginCollapsibleList(),
      pluginPoll(),
    ];
  }, []);

  if (!post || !siteMetadata) {
    return <></>;
  }

  const textFont = styles.get(stylesParams.textFont);

  return (
    <div data-hook="PostContent-wrapper">
      <WixRicosViewer
        wixExperiments={experiments}
        content={post.content}
        plugins={plugins}
        isMobile={siteMetadata.isMobile}
        locale={siteMetadata.locale}
        instance={siteMetadata.instance!}
        biSettings={{
          consumer: 'communities-forum-client',
          platform: 'Livesite',
          defaultParams: {},
          biCallbacks: {},
        }}
        linkSettings={{
          anchorTarget: '_blank',
          rel: { nofollow: true, sponsored: false, ugc: true },
        }}
        theme={{
          palette: {
            textColor: styles.get(stylesParams.textColor).value,
            actionColor: styles.get(stylesParams.linkColor).value,
          },
          typography: {
            fontFamily: styles.get(stylesParams.textFont).family,
          },
          customStyles: {
            p: {
              fontSize: `${textFont.size}px`,
              fontFamily: textFont.family,
              fontWeight: textFont.style?.bold ? 'bold' : 'normal',
              fontStyle: textFont.style?.italic ? 'italic' : 'normal',
              textDecoration: textFont.style?.underline ? 'underline' : 'none',
              color: styles.get(stylesParams.textColor).value,
            },
          },
        }}
      />
    </div>
  );
};

export default Widget;
