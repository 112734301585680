import { createSelector } from '@reduxjs/toolkit';

import { ERRORS_SLICE_NAME } from './errors-slice';
import { ErrorsState } from './errors-slice.models';

interface PartialState {
  [ERRORS_SLICE_NAME]: ErrorsState;
}

export const getErrorsSlice = (state: PartialState) => state[ERRORS_SLICE_NAME];

export const getCurrentError = createSelector(
  getErrorsSlice,
  slice => slice.error,
);
