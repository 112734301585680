import { createSelector } from '@reduxjs/toolkit';

import { TRANSLATIONS_SLICE_NAME } from './translations-slice';
import { TranslationsState } from './translations-slice.models';

interface PartialState {
  [TRANSLATIONS_SLICE_NAME]: TranslationsState;
}

export const getTranslationsSlice = (state: PartialState) =>
  state[TRANSLATIONS_SLICE_NAME];

export const getIsLoadingTranslations = createSelector(
  getTranslationsSlice,
  state => state.isLoading,
);

export const getTranslations = createSelector(
  getTranslationsSlice,
  state => state.translations,
);
