import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { PostContentController } from './post-content-controller.type';

const createController: CreateControllerFn = async ({
  controllerConfig: { setProps, wixCodeApi },
  flowAPI,
}) => {
  return {
    async pageReady() {
      setProps({
        siteMetadata: {
          locale: flowAPI.environment.language,
          isMobile: flowAPI.environment.isMobile,
          instance: wixCodeApi.user.currentUser.instance,
        },
      });
    },
    exports: (): PostContentController => {
      return {
        controllerName: 'postContent',
        setPost: (post) => setProps({ wfState: { post } }),
      };
    },
  };
};

export default createController;
