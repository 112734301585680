import { FILTER_INPUT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'

const isConnected = (connectionsGraph, from, to) => {
  if (from === to) {
    return true
  }
  return connectionsGraph[from]?.some(compId =>
    isConnected(connectionsGraph, compId, to),
  )
}

export const getComponentsToUpdate = ({
  connectedComponents,
  updatedCompIds,
  datasetIsReal,
  connectionsGraph,
}) => {
  if (updatedCompIds.length && datasetIsReal) {
    const connectedCompsToUpdate = []
    for (const connectedComponent of connectedComponents) {
      const { role, compId } = connectedComponent
      if (
        updatedCompIds.some(
          updatedCompId =>
            isConnected(connectionsGraph, compId, updatedCompId) ||
            isConnected(connectionsGraph, updatedCompId, compId),
        )
      ) {
        if (role === FILTER_INPUT_ROLE) return connectedComponents
        connectedCompsToUpdate.push(connectedComponent)
      }
    }

    return connectedCompsToUpdate
  }

  return connectedComponents
}
